import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'content',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'shipping-order-entry-edit-page',
    loadChildren: () => import('./shipping-order-entry-edit-page/shipping-order-entry-edit-page.module').then( m => m.ShippingOrderEntryEditPagePageModule)
  },
  {
    path: 'edit-time-booking-page',
    loadChildren: () => import('./edit-time-booking-page/edit-time-booking-page.module').then( m => m.EditTimeBookingPagePageModule)
  },
  {
    path: 'my-files',
    loadChildren: () => import('./my-files/my-files.module').then( m => m.MyFilesPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
